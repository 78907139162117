:root {
  --sidebarwidth: 300px;
  --contentpadding: 20px;
  --error-color: #dc3545;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3f4;

  font-family: 'Open Sans', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

header {
  background-color: green;
}

/* START LAYOUT sidebar https://bootstrapious.com/p/bootstrap-sidebar */
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: var(--sidebarwidth);
  max-width: var(--sidebarwidth);
  background: #ffffff;
  transition: all 0.3s;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

#sidebar.active {
  margin-left: calc(var(--sidebarwidth) * -1);
}
#content {
  width: 100%;
  padding: var(--contentpadding);
  /* min-height: 100vh; */
  transition: all 0.3s;
  position: relative; /* to allow positioning of sidebar collapse button */
}
#sidebarCollapse {
  position: absolute;
  left: 2px;
  top: 2px;
}
/* END LAYOUT sidebar */

.bg-rlck-primary {
  background-color: #b40890;
  /* background-color: #17a2b8; */
}
.bg-rlck-primary-light {
  background-color: #f1d5ea;
  /* background-color: #afc7e4; */
}

.bg-secondary-light {
  background-color: #cdcdcd;
}

.txt-white {
  color: white;
}

.m-icon {
  font-size: 24;
  vertical-align: middle;
}

input.invalid {
  border-color: var(--error-color);
}
.red-text {
  color: var(--error-color);
}
.async-validating {
  color: green;
}
.generalFormError {
  color: var(--error-color);
  font-weight: bold;
  display: block;
}
.modal-90w {
  width: 90%;
  max-width: 90%;
}

.mediamanagerlist {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.mediamanagerlist img {
  border: 3px solid white;
}
.mediamanagerlist li.active img {
  border: 3px solid red;
}

.sideeffectssection {
}
.sideeffectssection ul {
  list-style: none;
  max-height: 300px;
  /*overflow: auto;*/
}

.questionoptionsul {
  /*max-height: 200px;*/
  /*overflow: auto;*/
}
.condition_container {
  margin-bottom: 1em;
}
.condition_row .condition_definition select,
.condition_row .condition_definition input,
.action_row .action_definition select,
.action_row .action_definition input {
  display: inline-block;
  max-width: 150px;
  margin-left: 0.6em;
  margin-right: 0.6em;
}

.condition_row .condition_definition select:last-child,
.condition_row .condition_definition input:last-child,
.action_row .action_definition select:last-child,
.action_row .action_definition input:last-child {
  margin-right: 0;
}

.condition_row .condition_definition .someNumber {
  max-width: 75px;
}
.condition_row .condition_definition .some {
  max-width: 110px;
}
.condition_row .condition_definition .verb {
  max-width: 190px;
}
.condition_row .condition_type select {
  /*max-width:250px;*/
}
.condition_row {
  margin: 0;
  padding: 0.4em 0;
}
.form-control.no_separate_error.error {
  border-color: #f88787 !important;
  box-shadow: 0 0 0 0.2rem rgb(255 0 0 / 25%) !important;
}

/*.submission_item {*/
/*  border:1px solid black;*/
/*}*/

.WysiwygEditor .rdw-editor-wrapper {
  border: 1px solid #e3e0e0;
  margin-bottom: 1em;
  padding: 0.5em;
}
.WysiwygEditor .rdw-editor-main {
  overflow: auto;
  max-height: 300px;
  min-height: 180px;
}
.WysiwygEditor.short-rte .rdw-editor-main {
  min-height: 100px;
}
.gamelistcardtext {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 100px; /* although the above is supposed to work also on firefox, it does not. So add an additional max-height */
}

.collapse-button {
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 0px;
  background-color: transparent;
}
.collapse-button.open::before {
  content: '▾ ';
}
.collapse-button.closed::before {
  content: '▸ ';
}

.clickable-collapsible-label {
  cursor: pointer;
  color: #0075ff;
}

.accordion-content {
}

.inline-media-field {
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-color: lightgray;
  margin-left: 3px;
  border-radius: 2px;
  width: 38px;
  height: 38px;
  padding: 2px;
}

.media-thumb-container {
  position: relative;
}
.media-button {
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
  opacity: 0.8;
}
.media-button:hover {
  opacity: 1;
}
.media-button + .media-button {
  right: 2.6rem;
}
.media-button.edit-media-button {
}
.media-button.remove-media-button {
}

.card-header.statement-header,
.card-header.submission-header,
.card-header.archetypesub-header {
  background-color: #f7fdee;
}
.help-icon {
  color: #0062cc;
  font-weight: bold;
  cursor: pointer;
}

.legend-small {
  font-size: 1.05rem;
}
.legend-medium {
  font-size: 1.45rem;
}

.invalid-feedback {
  /* to xrisimopoiw sto renderBooleanRadio2 kai by default de fainetai, opote to deixnw edw */
  display: block;
}
.form-check-inline .invalid-feedback {
  margin-top: 0;
  margin-left: 0.5em;
}

.logo-container img {
  max-width: 80%;
  max-height: 100px;
  text-align: center;
}

.form-check.placement-fix {
  margin-top: 36px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #aeaeae;
}
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.dropdown-item.disabled {
  opacity: 0.6;
}
.form-check.placement-fix-inline,
.form-label.placement-fix-inline {
  margin-top: 8px;
}

.marker-map,
.google-map-location-form {
  border: 1px solid #d1d1d1;
}

.menu-subheading {
  margin: 0 -10px;
  color: white;
  padding: 0.3em;
  margin-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.menu-subsection {
  margin: 0 -10px;
  padding: 0 15px;
}

.scene-objects-list {
  display: block;
  width: 100%;
  max-height: 700px;
  overflow: auto;
}

.react-bootstrap-table .sortable {
  cursor: pointer;
}

.react-bootstrap-table .sortable .order .dropdown:after {
  content: '-';
  display: inline-block;
  padding: 0 4px;
}
.react-bootstrap-table .sortable .react-bootstrap-table-sort-order .caret:after {
  content: '⌄';
  display: inline-block;
  padding: 0 4px;
  font-size: 13px;
  line-height: 1;
}
.react-bootstrap-table .sortable .react-bootstrap-table-sort-order.dropup .caret:after {
  transform: rotateX(180deg);
}

body .text-warning {
  color: #e3a00c !important;
}

a {
  cursor: pointer;
}

.analytics h5 {
  font-size: 15px;
  font-weight: bolder;
}

.analytics:not(.modal) ul.list-group {
  overflow: auto;
  max-height: 260px;
}
.analytics ul.list-group li {
  font-size: 14px;
}
.analytics ul.list-group li a {
  cursor: pointer;
}
.analytics ul.list-group li small {
  font-size: 12px;
  color: #666;
}
.analytics ul.list-group li small a {
  cursor: pointer;
  text-decoration: underline;
}
.analytics ul.list-group li small strong {
  color: #333;
}

.popover-body {
  max-height: 400px;
  overflow: auto;
}

/* Conversation Chat */
.conversation-chat {
  display: flex;
  flex-direction: column;
}
.conversation-chat__message {
  width: 80%;
}
.conversation-chat__message + .conversation-chat__message {
  margin-top: 20px;
}
.conversation-chat__message p {
  margin-bottom: 0;
}
.conversation-chat__message p + p {
  margin-top: 15px;
}
.conversation-chat__message--player {
  align-self: flex-end;
  text-align: right;
}
.conversation-chat__message--npc {
  align-self: flex-start;
}
.conversation-chat__bubble {
  background: #eaeaea;
  padding: 15px;
  border-radius: 5px;
  width: fit-content;
  margin-top: 5px;
}
.conversation-chat__message--player .conversation-chat__bubble {
  margin-left: auto;
}
.conversation-chat__speaker-name {
  font-size: 16px;
}
.conversation-chat__timestamp {
  font-size: 14px;
  color: #777;
}

.list-group-item .no-wrap {
  white-space: nowrap;
}
