/* Add spinner symbol */
.spinner::before {
    /* You can choose from a few below */
    --lighthouse-symbol: "\26EF";
    --dotted-circle: "\25CC";
    --hourglass: "\23F3";

    /* Assign the spinner icon as content for the pseudo element */
    content: var(--lighthouse-symbol);
}
.spinner {
    /* In our demo, the spinner uses an <i/> tag which by default has font-styling. We reset that here */
    font-style: normal;

    /* Increase font size to make it more appealing */
    font-size: 5em;

    /* Add infinite rotation animation */
    animation: rotation 5s infinite linear;
}

/* Specify rotation */
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}



.spinnerwrapper {
    height: 150px;
    width: 150px;
    margin: auto;
    display: flex;
}

.spinner {
    margin: auto;
}