:root {
  --collapsible-height: 500px;
}

.g4mcollapsible {
  background-color: rgba(255, 255, 255, 0.3);
}
.g4mcollapsible {
  margin-bottom: 5px;
}
.g4mcollapsible.closed .g4mcollapsible-heading {
  color: #555;
}
.g4mcollapsible.closed .g4mcollapsible-heading:hover {
  background-color: #eaeaea;
}
.g4mcollapsible:hover .g4mcollapsible-heading,
.g4mcollapsible .g4mcollapsible-heading {
  color: #222;
}
.g4mcollapsible.opened .showclosed {
  display: none;
}

.g4mcollapsible.opened.nested-collapse .showopened {
  display: initial;
}

.g4mcollapsible.opened.nested-collapse .showclosed {
  display: none;
}

.g4mcollapsible.opened.nested-collapse {
  border: 1px solid #e7a6f5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.g4mcollapsible.closed .showopened {
  display: none;
}

.g4mcollapsible.closed.nested-collapse .showclosed {
  display: initial;
}
.g4mcollapsible.closed.nested-collapse .showopened {
  display: none;
}

.g4mcollapsible.opened .g4mcollapsible-heading {
  border-bottom: 1px solid #aeaeae;
  margin-bottom: 0px;
}
.g4mcollapsible.closed.nested-collapse .g4mcollapsible-heading {
  border-bottom: 0px solid #aeaeae;
  margin-bottom: 0px;
}
.g4mcollapsible-heading {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem;
  padding-left: 5px;
  padding-right: 10px;
  margin-right: -10px;
  margin-left: -10px;
}
.g4mcollapsible-heading > div {
  cursor: pointer;
}
.g4mcollapsible-content {
  list-style: none;
  padding: 0.2rem;
  margin: 0;
  max-height: var(--collapsible-height);
  overflow: auto;
}
.g4mcollapsible-content li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem;
}
.g4mcollapsible-content li .controls {
  display: flex;
  justify-content: space-between;
}

.g4mcollapsible.sideBar .controlLabel {
  display: none;
}
